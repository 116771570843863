$font-r: "Montserrat-Regular";
$font-m: "Montserrat-Medium";
$font-sb: "Montserrat-SemiBold";
$font-b: "Montserrat-Bold";
$font-eb: "Montserrat-ExtraBold";

@font-face {
    font-family: $font-r;
    src: url("../../fonts/Montserrat-Regular.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: $font-m;
    src: url("../../fonts/Montserrat-Medium.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: $font-sb;
    src: url("../../fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: $font-b;
    src: url("../../fonts/Montserrat-Bold.ttf") format("truetype");
    font-display: swap;
}
@font-face {
    font-family: $font-eb;
    src: url("../../fonts/Montserrat-ExtraBold.ttf") format("truetype");
    font-display: swap;
}
