/* You can add global styles to this file, and also import other style files */
@import "./abstracts/variables";
@import "./abstracts/functions";
@import "./base/fonts";
@import "./base/custom";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  font-family: $font-r, sans-serif;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

:host ngx-intl-tel-input ::ng-deep .country-dropdown {
  left: 10px !important;
  right: auto !important;

  transform: translateY(-100px) !important;
  bottom: auto !important;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // -webkit-text-fill-color: $grey-13 !important;
  -webkit-box-shadow: 0 0 0px 100px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

:ng-deep.iti.separate-dial-code input .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input,
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
  padding-left: 145px;
  right: 0;
  left: auto;
  padding-left: 1px;
  padding-right: 1px;
}
.iti .dropdown-menu.country-dropdown,
.dropdown-menu-show {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
  right: 0;
  left: auto;
  padding-left: 1px;
  padding-right: 1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}
.iti {
  display: block !important;
  // margin-bottom: 32px;
}

// .iti__flag-container.open + input {
//   border-bottom-left-radius: 0px;
//   border-bottom-right-radius: 0px;
// }
// input#phone {
//   padding-left: 70px;
//   height: 80px;
// }
:host ::ng-deep.dropdown-menu.show {
  display: none;
  /* position: relative; */
  /* float: left; */
  /* top: 115px; */
  inset: 100% auto auto -200px;
  /* transform: translateY(0px); */
  transform: translateY(88px);
}
.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
  background: none;
}

.iti .search-container input:focus {
  outline: none;
}

input#phone::placeholder {
  color: $grey-13;
  font-family: $font-r;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 32px;
  padding-right: 10px;
}
input#phone {
  padding-left: 115px;
  // height: 80px;
  width: 100%;
}
.icon-phone {
  background-image: url(/../assets/images/Contactus/call.webp);
  background-repeat: no-repeat;
  background-position: 9.54px;
  background-size: 21px 20px;

  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  background-color: white;
}
.icon-phone:focus-within {
  background-image: url(../assets/images/Contactus/call-g.webp);

}

ngx-intl-tel-input input#phone {
  margin-bottom: 17.81px;
  padding-left: 115px;
  width: 100%;
  font-size: 14px;
  height: 46px;
  background-color: transparent;
  border: 1px solid #dadada;
}
ngx-intl-tel-input input#phone:focus {
  margin-bottom: 17.81px;
  padding-left: 115px;
  width: 100%;
  font-size: 14px;
  height: 46px;
  background-color: transparent;
  border: 1px solid $primaryColor;
  outline: none !important;
  border-color: $primaryColor;
  box-shadow: 0 0 0 0 #000;
}

@media screen and (min-width: 768px) {
  ngx-intl-tel-input input#phone {
    font-size: 16px;
  }
  ngx-intl-tel-input input#phone:focus {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  input#phone::placeholder {
    font-size: 18px;
  }
  input#phone {
    padding-left: 157px;
    height: 80px;
  }
  .iti--allow-dropdown .iti__flag-container,
  .iti--separate-dial-code .iti__flag-container {
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 63px;
    padding-right: 25px;
  }
  .icon-phone {
    background-position: 24px;
    background-size: 32px 34px;
    font-size: 18px !important;
  }
  .icon-phone:focus-within {
    background-position: 24px;
    background-size: 32px 34px;
    font-size: 18px;
  }
  ngx-intl-tel-input input#phone {
    margin-bottom: 32px;
    padding-left: 157px;
    font-size: 18px;
    height: 80px;
  }
  ngx-intl-tel-input input#phone:focus {
    margin-bottom: 32px;
    padding-left: 157px;
    font-size: 18px;
    height: 80px;
  }
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
