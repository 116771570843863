// Colors
$primaryColor: #609d50;
$secondaryColor-1: #b1f19d;
$secondaryColor-2: #72ba60;
$secondaryColor-3: #ecb027;
$secondaryColor-4: #0d7280;
$secondaryColor-5: #dff8d5;
$black-1: #141414;
$black-2: #1c1c1c;
$grey-1: #c3c3c1;
$grey-2: #dcdcdc;
$grey-3: #dddddd;
$grey-4: #828282;
$grey-5: #f8f8f8;
$grey-6: #d9d9d9;
$grey-7: #585858;
$grey-8: #fafafa;
$grey-9: #5c5c5c;
$grey-10: #e2e8f0;
$grey-11: #f3f3f3;
$grey-12: #bdbdbd;
$grey-13: #a7a7a7;
$black: #000;
$white: #fff;
$error: #c41d1d;

$boxShadow-1: 0px 4px 30px rgba(0, 0, 0, 0.09);
$boxShadow-2: 0px 4px 36px $grey-2;
$boxShadow-3: 1px 1px 5px $grey-12;
